<template>
  <div class="main-wrapper" v-if="loaded">
    <template v-if="!accomplish">
      <div class="wrapper">
        <div class="content-wrapper">
          <div class="progress-wrapper">
            <DaliProgress :startFrom="startFrom"></DaliProgress>
            <div class="text">
              报告生成中，预计2小时内完成，请耐心等待。前往
              <router-link :to="{ name: 'test-log' }">检测记录</router-link>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <keep-alive>
        <router-view />
      </keep-alive>
    </template>
  </div>
</template>

<script>
import DaliProgress from "./components/DaliProgress";
import { getProgressBar, loginChangeToken } from "../../api/api";

export default {
  name: "Always",
  components: {
    DaliProgress,
  },
  props: {
    uuid: String,
    shareCode: String,
  },
  data() {
    return {
      loaded: false,
      startFrom: 0,
      timer: null,
      accomplish: false,
    };
  },
  mounted() {
    if (this.$route.query.fromHouTai == "houtai") {
      loginChangeToken(this.$route.query.token).then((res) => {
        localStorage.setItem("authorizationFromHouTai", res.data);
        this.loaded = true;
        this.accomplish = true;
      });
    } else {
      this.loadProgress();
      this.timer = setInterval(() => {
        this.loadProgress();
      }, 1000 * 60);
    }
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    async loadProgress() {
      const res = await getProgressBar(this.uuid);
      this.startFrom = res.data.exeDate;
      this.loaded = true;
      if (res.data.accomplish) {
        this.accomplish = true;
        clearInterval(this.timer);
      }
    },
  },
  computed: {},
};
</script>

<style lang="stylus" scoped>
.main-wrapper {
  min-height 100vh
}

.wrapper
  min-height 100vh
  overflow hidden
  background #fbfbfb

  .content-wrapper
    box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
    padding 10px 0
    border-radius: 8px;
    min-height 768px
    width 1280px;
    margin 50px auto;
    background #fff;
    display flex
    align-items center
    justify-content center
    .progress-wrapper
      display flex
      align-items center
      justify-content center
      flex-direction column
    .text
      font-size: 18px;
      font-weight: 600;
      color: rgba(0,0,0,0.8);
      line-height: 25px;
      margin-top 50px
</style>
